/*
 * @Last Modified by: zhu.xiaoting
 * @Last Modified time: 2023-04-20 16:56:07
 */
<template>
  <div class="all flex-centent">
    <div class="main">
      <!-- 公告 -->
      <div v-show="showBoard" class="board">
        <img src="@/assets/board_tipicon.svg" class="board_tipicon" />
        <div class="board_info_container" v-html="boardInfo"></div>
        <!-- <div class="board_info_container">温馨提示 : 因工作需要,拟于2023年1月14日(星期六)12:00至18:00对本系统进行停机维护,维护期间暂停系统运行。给大家带来不便,敬请谅解;</div> -->
        <img @click="showBoard=false" src="@/assets/icon_cancel.png" class="board_close" alt="关闭">
      </div>
      <!-- <div v-show="showBoard" class="board notice_card_wrapper">
        <img src="@/assets/board_tipicon.svg" class="board_tipicon" />
        <div class="inner_container">温馨提示 : 因工作需要,拟于2023年1月14日(星期六)12:00至18:00对本系统进行停机维护,维护期间暂停系统运行。给大家带来不便,敬请谅解;</div>
        <img @click="showBoard=false" src="@/assets/icon_cancel.png" class="board_close" alt="关闭">
      </div> -->
      <!-- 工作任务 -->
      <div class="work-task flex-row justify-between">
        <div
          class="workItem daiban flex-row justify-between"
          @click="workItemClick(0)"
        >
          <div class="left flex-column">
            <span class="title">我的待办</span>
            <span class="num">查看</span>
          </div>
          <div class="right">
            <img src="@/assets/daiban.png" />
          </div>
        </div>
        <div
          class="workItem faqi flex-row justify-between"
          @click="workItemClick(1)"
        >
          <div class="left flex-column">
            <span class="title">我的发起</span>
            <span class="num">查看</span>
          </div>
          <div class="right">
            <img src="@/assets/faqi.png" />
          </div>
        </div>
        <div
          class="workItem chuli flex-row justify-between"
          @click="workItemClick(2)"
        >
          <div class="left flex-column">
            <span class="title">我的处理</span>
            <span class="num">查看</span>
          </div>
          <div class="right">
            <img src="@/assets/chuli.png" />
          </div>
        </div>
      </div>
      <div class="informationAnn">
        <div
          class="informationAnn-title flex-row justify-between justify-center"
        >
          <span class="title">资讯动态</span>
          <a style="color: #999" @click="informationAnnMore">查看更多</a>
        </div>
        <div class="line_h"></div>
        <a-spin :spinning="spinning">
          <div class="list">
            <div
              class="item flex-row align-center"
              v-for="(item, index) in informationAnnList"
              :key="item.id"
            >
              <span class="index">{{ "0" + (index + 1) }}</span>
              <span class="item-title" @click="informationAnnItemClick(item)">{{
                item.title
              }}</span>
            </div>
          </div>
        </a-spin>
      </div>
      <div class="Echarts">
        <div
          :ref="item.id"
          class="chart"
          v-for="item in charList"
          :key="item.id"
          :style="{ height: '550px', width: '100%' }"
        ></div>
      </div>
    </div>
  </div>
  <!-- 弹出工作台 -->
  <a-modal
    :title="modalTitle"
    :visible="visible"
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    :footer="null"
    :closable="true"
    :width="modalWidth"
    @ok="handleOk"
    @cancel="handleOk"
  >
    <a-spin :spinning="spinning">
      <a-table
        :columns="columns"
        :data-source="list"
        bordered
        :rowKey="(record) => record.id"
        :pagination="pagination"
        @change="paginationChange"
        :scroll="{ x: 1200 }"
      >
        <template #subject="{ record }">
          <a-tooltip>
            <template #title>{{ record.subject }}</template>
            <span
              class="subject_title"
              style="width: 300px"
              @click="subject(record)"
            >
              {{ record.subject }}
            </span>
          </a-tooltip>
        </template>
        <template #state="{ record }">
          <span v-for="item in status" :key="item.key">
            <a-tag color="blue" v-if="record.state === item.key">
              {{ item.value }}</a-tag
            >
          </span>
        </template>
      </a-table>
    </a-spin>
  </a-modal>
  <!-- 弹出详情 -->
  <a-modal
    :title="modalTitle"
    :visible="detailsVisible"
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    :footer="null"
    :closable="true"
    :width="modalWidth"
    @ok="handleOk"
    @cancel="handle"
  >
    <a-spin :spinning="spinning">
      <div class="iframe-layout" :style="{ minHeight: minHeight + 'px' }">
        <iframe class="iframe" :src="url"></iframe>
      </div>
    </a-spin>
    <!-- </div> -->
  </a-modal>

  <!-- 弹出资源更新公告 -->
  <a-modal
    title="资源更新公告"
    :visible="noticeModalVisible"
    :maskClosable="false"
    :footer="null"
    :width="modalWidth"
    centered
    destroyOnClose
    :keyboard="false"
    @cancel="confirmUpdatedNotice"
  >
  <!-- :style="{height: 520 + 'px'}" -->
    <div class="noticeModal" :style="{maxHeight: 520 + 'px',minHeight: 300 + 'px', 'overflow-y': 'auto'}">
      <div class="notice_content">
        <div class="board_img">
          <img :src="imgPath + '/39fdd8e1-f927-3be1-af35-f929a70cbf6e/board_drawing_1.svg'" alt="背景">        </div>
        <div class="modal_update_content">
          <p style="font-size: 16px;">
            <span style="font-size: 18px;">更新版本 : {{updatedNoticeObj.version}}</span>
            <span style="color: #999999; margin-left: 20px;">{{updatedNoticeObj.time}}</span>
          </p>
          <p class="notice_html" v-html="updatedNoticeObj.details"></p>
        </div>
      </div>
      <div class="board_btn">
        <a-button @click="confirmUpdatedNotice" class="btn" type="primary">确定</a-button>
      </div>
    </div>
  </a-modal>
  <!-- <a-modal
    :visible="tenantVisible"
    :confirm-loading="confirmLoading"
    :width="tenantWidth"
    ok-text="确定"
    cancel-text="取消"
    :maskClosable="false"
    @cancel="tenantFirst"
    @ok="tenantFirstOK"
  >
    <div class="flex-column align-center">
      <div><img src="@/assets/yingyongchahua.png" alt="" /></div>
      <div class="describe">优质应用推荐，快去添加吧！</div>
    </div>
  </a-modal> -->
  <!-- <a-modal
    :visible="versionUpdateVisible"
    :confirm-loading="confirmLoading"
    ok-text="确定"
    cancel-text="取消"
    :maskClosable="false"
    @cancel="versionUpdateOK"
    @ok="versionUpdateOK"
  >
    <div class="flex-column align-center">
      <div><img src="@/assets/card_banbengengxin.png" alt="" /></div>
      <div class="describe">版本更新</div>
    </div>
    <div v-html="detail"></div>
  </a-modal> -->
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { getCodePlatformUrl, getCodeDesignUrl, getEnvVars } from '@/api/config'
import * as echarts from '@/assets/echarts_min'
import { minheight } from '../components/sameVariable'
import { getTableColumnData } from '@/api/tableAbout/tableAPI'
const { imgUrl } = getEnvVars()

export default defineComponent({
  data () {
    return {
      imgPath: imgUrl,
      showBoard: false, // 显示顶部提示语
      boardInfo: '',
      noticeModalVisible: false, // 显示资源更新公告弹框
      updatedNoticeObj: {},
      modalHeight: 0,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        invoiceState: '',
        orderState: '',
      }, // 分页部分
      selectedRows: [], // 当前选中部分
      codePlatformUrl: '',
      codeDesignUrl: '',
      spinning: false,
      modalTitle: '',
      visible: false,
      detailsVisible: false,
      // tenantVisible: false,
      versionUpdateVisible: false,
      tenantId: '',
      confirmLoading: false,
      modalWidth: '1200px',
      detail: '',
      columns: [],
      list: [],
      // tenantWidth: '300px',
      work: {
      }, // 工作代办数据
      informationAnnList: [], // 资讯列表
      url: null,
      myToDoItems: '/#/myapprova', // 我的待办
      charList: [], // 图表菜单
      status: [
        { key: 'draft', value: '草稿' },
        { key: 'running', value: '运行中' },
        { key: 'suspend', value: '挂起' },
        { key: 'end', value: '结束' },
        { key: 'manualend', value: '人工结束' },
        { key: 'rejectToStart', value: '驳回到发起人' },
        { key: 'reject', value: '驳回' },
        { key: 'revoke', value: '撤销' },
        { key: 'revokeToStart', value: '撤销' }],
    }
  },
  created () {
    // console.log('document.body.clientHeight', document.body.clientHeight);
    this.modalHeight = document.body.clientHeight * 0.6
    this.minHeight = minheight
    this.getBoardInfo()
    this.getInformationAnn()
    this.getProfile()
  },
  mounted () {
    this.getUpdateInfo()
    this.getUserChartMenuDataAsync()
    this.versionUpdate()
    this.codePlatformUrl = getCodePlatformUrl()
    this.codeDesignUrl = getCodeDesignUrl()
  },
  methods: {
    // 跳转详情低代码
    subject (item) {
      this.detailsVisible = true
      const token = JSON.parse(localStorage.getItem('token'))
      const tenantList = JSON.parse(localStorage.getItem('tenantList'))
      this.tenantId = tenantList.tenantId
      const detailList = JSON.parse(JSON.stringify(item).replace(/state/g, 'status'))
      const datail = JSON.stringify(detailList)
      let pathaddress = ''
      switch (this.modalTitle) {
        case '我的待办':
          pathaddress = '/#/officeDesk/processCenter/pendingManage/pendingItems'
          break
        case '我的发起':
          pathaddress = '/#/officeDesk/processCenter/myStartPro/myRequest'
          break
        default:
          pathaddress = '/#/officeDesk/processCenter/myUndertakePro/handledMatters'
          break
      }
      this.url = this.codeDesignUrl + pathaddress + '?layout=none&access_token=' + token.access_token + '&tenant_id=' + this.tenantId + '&datail=' + datail
    },
    // 获取表头
    async getTableColumnData (item) {
      const data = await getTableColumnData(item, 'ibpsMessage', true)
        .catch(err => {
          this.spinning = false
          console.log(err)
        })
      const list = data
      const arr = []
      list.map((item, index) => {
        arr.push(Object.assign({}, item, { width: 150 }))
      })
      this.columns = arr
    },
    // 分页
    paginationChange (e) {
      this.pagination.current = e.current
      this.getReceivedTableData()
    },
    // 获取我的待办数据
    getBacklogTableData () {
      this.spinning = true
      api
        .post('/api/app/work-message/my-backlog', {
          parameters: [
          ],
          requestPage: {
            limit: this.pagination.pageSize,
            pageNo: this.pagination.current,
            totalCount: this.pagination.total,
          },
          sorts: [
          ],
        })
        .then(({ data }) => {
          this.spinning = false
          this.list = data.dataResult
          this.pagination.total = data.pageResult.totalCount
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取账户信息失败' + err)
          console.log(err)
        })
    },
    // 获取我的发起数据
    getInitiatedTableData () {
      this.spinning = true
      api
        .post('/api/app/work-message/my-initiated', {
          parameters: [
          ],
          requestPage: {
            limit: this.pagination.pageSize,
            pageNo: this.pagination.current,
            totalCount: this.pagination.total,
          },
          sorts: [
          ],
        })
        .then(({ data }) => {
          this.spinning = false
          this.list = JSON.parse(JSON.stringify(data.dataResult).replace(/status/g, 'state'))
          this.pagination.total = data.pageResult.totalCount
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取账户信息失败' + err)
          console.log(err)
        })
    },
    // 获取我的处理数据
    getReceivedTableData () {
      this.spinning = true
      api
        .post('/api/app/work-message/my-received', {
          parameters: [
          ],
          requestPage: {
            limit: this.pagination.pageSize,
            pageNo: this.pagination.current,
            totalCount: this.pagination.total,
          },
          sorts: [
          ],
        })
        .then(({ data }) => {
          this.spinning = false
          this.list = JSON.parse(JSON.stringify(data.dataResult).replace(/status/g, 'state'))
          this.pagination.total = data.pageResult.totalCount
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取账户信息失败' + err)
          console.log(err)
        })
    },
    getProfile () {
      this.spinning = true
      api
        .get('/api/app/app-profile', {
        })
        .then(({ data }) => {
          this.spinning = false
          this.obj = data
          if (data.status === 1) {
            this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
            window.location.href = '/account/login'
            window.localStorage.clear()
          }
          localStorage.setItem('userInfo', JSON.stringify(data))
        })
        .catch(err => {
          this.spinning = false
          console.log(err)
        })
    },
    getBoardInfo() { // 系统公告
      api
        .get('/api/app/sys-information-ann/information-ann')
        .then(({ data }) => {
          console.log('-------getBoardInfo', data)
          /// 端口类型：0PC端、1移动端
          if (data && data.portType.split(',').includes('0')) {
            this.boardInfo = data.titleDetails
            this.showBoard = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getUpdateInfo() { // 系统更新提示
      api
        .get('/api/app/sys-information-ann/product-updates-ann')
        .then(({ data }) => {
          console.log('-------getUpdateInfo', Boolean(data), data)
          /// 端口类型：0PC端、1移动端
          if (data && data.portType.split(',').includes('0')) { // data存在且portType是0 pc端时显示公告
            const lastUpdatedTime = localStorage.getItem('lastUpdatedTime')
            console.log('lastUpdatedTime', lastUpdatedTime)
            if (lastUpdatedTime && lastUpdatedTime === data.concurrencyStamp) {
              this.noticeModalVisible = false

              return
            }
            this.updatedNoticeObj = {
              version: data.title,
              time: data.creationTime ? data.creationTime.slice(0, 16).replaceAll('T', ' ') : '',
              details: data.titleDetails,
              concurrencyStamp: data.concurrencyStamp
            }
            this.noticeModalVisible = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirmUpdatedNotice() { // 系统更新提示的确认按钮点击事件
      this.noticeModalVisible = false
      // 点击确定后时间戳记录在本地缓存，后续刷新后不再弹框提示
      localStorage.setItem("lastUpdatedTime", this.updatedNoticeObj.concurrencyStamp)
    },
    versionUpdate () {
      api
        .get('/api/app/version-update/version-app')
        .then(({ data }) => {
          if (data.id) {
            this.versionUpdateVisible = true
            this.detail = data.detail
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    versionUpdateOK () {
      this.confirmLoading = true
      api
        .post('/api/app/user-update/input-user-app')
        .then(({ data }) => {
          this.versionUpdateVisible = false
        })
        .catch(err => {
          console.log(err)
          this.versionUpdateVisible = false
          this.confirmLoading = false
        })
    },
    myEcharts () {
      this.charList.forEach(element => {
        this.$nextTick(() => {
          // eslint-disable-next-line no-undef
          const myChart = echarts.init(this.$refs[element.id])
          myChart.setOption(element)
        })
      })
    },
    // 关闭弹窗事件
    handleOk (e) {
      this.visible = false
    },
    handle (e) {
      this.url = 'about:blank'
      this.detailsVisible = false
    },
    // 三个工作部分点击事件
    workItemClick (e) {
      /*       this.$router.push({
              path: '/WorkManagement/WorkTask',
              query: {
                type: e,
              },
            }) */
      this.visible = true
      // var currentPage = ''
      switch (e) {
        case (0): {
          // currentPage = this.myToDoItems
          this.modalTitle = '我的待办'
          this.getTableColumnData('myBackLog')
          this.getBacklogTableData()
          break
        }
        case (1): {
          // currentPage = this.myInitiate
          this.modalTitle = '我的发起'
          this.getTableColumnData('myInitiated')
          this.getInitiatedTableData()
          break
        }
        case (2): {
          // currentPage = this.myProcedure
          this.modalTitle = '我的处理'
          this.getTableColumnData('myReceived')
          this.getReceivedTableData()
          break
        }
      }
    },
    // 获取最新发布的三个文章
    getInformationAnn () {
      this.spinning = true
      api
        .get('/api/app/sys-information-ann/paging-sys-information-ann-list', {
          params: {
            PageSize: 3,
            Pagination: 1,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.informationAnnList = data.list
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取资讯动态失败')
          console.log(err)
        })
    },
    // 更多资讯
    informationAnnMore () {
      this.$router.push({ path: '/WorkManagement/InformationAnnList' })
    },
    // 资讯项点击事件
    informationAnnItemClick (e) {
      this.$router.push({
        path: '/WorkManagement/InformationAnnDetail',
        query: {
          id: e.id,
        },
      })
    },
    // 获取用户图表菜单的数据
    getUserChartMenuDataAsync () {
      api
        .get('/api/app/abp-user-application-menu/user-chart-menu-data')
        .then(({ data }) => {
          const charList = []
          // 处理数据
          data.forEach(e => {
            const option = e.field_options.reportForm
            option.id = 'chart' + e.id
            option.height = e.field_options.height
            const series = []
            // 处理serise里的数据，是个数组
            const xData = []

            switch (e.field_type) {
              case 'chart-line':
                e.field_options.defaultValue.func.series.forEach(seriesData => {
                  const data = []
                  seriesData.data.forEach(dataItem => {
                    xData.push(dataItem.name)
                    data.push(dataItem.value)
                  })
                  const seriesItem = e.field_options.series
                  seriesItem.data = data
                  seriesItem.type = 'line'
                  series.push(seriesItem)
                })
                option.series = series
                option.xAxis.data = xData

                option.xAxis.type = 'category'
                // option.xAxis.data = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                option.xAxis.boundaryGap = true
                option.yAxis.splitLine.show = true
                option.grid = {
                  left: '6%',
                  right: '6%',
                  bottom: '6%',
                  containLabel: true,
                }
                option.series.type = 'line'
                break
              case 'pie':
                e.field_options.defaultValue.func.series.forEach(seriesData => {
                  const seriesItem = e.field_options.series
                  seriesItem.data = seriesData.data
                  seriesItem.type = 'pie'
                  seriesItem.label.position = 'outside'
                  seriesItem.radius = '60%'
                  seriesItem.top = 80
                  seriesItem.stillShowZeroSum = true
                  series.push(seriesItem)
                })
                option.series = series
                option.legend.left = 'center'
                option.legend.top = 70
                option.legend.type = 'scroll'
                break
              case 'histogram':
                e.field_options.defaultValue.func.series.forEach(seriesData => {
                  const seriesItem = e.field_options.series
                  seriesItem.data = seriesData.data
                  seriesItem.barMaxWidth = 10
                  seriesItem.type = 'bar'
                  series.push(seriesItem)
                })
                option.series = series
                option.xAxis.data = e.field_options.defaultValue.func.categories
                option.xAxis.type = 'category'

                option.xAxis.boundaryGap = true
                option.yAxis.splitLine.show = true
                option.grid = {
                  left: '6%',
                  right: '6%',
                  bottom: '6%',
                  containLabel: true,
                }
                break
            }
            charList.push(option)
          })
          this.charList = charList
          this.myEcharts()
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
})

</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.iframe-layout {
  text-align: center;
  height: 85vh;
  width: 100%;

  .iframe {
    border: 0px none;
    height: 100%;
    width: 100%;
  }
}
.all {
  width: 100%;
  // background-color: pink;
  .main {
    width: 82%;
    // background-color: #fff;
    .board {
      width: 100%;
      background-color: #fff6eb;
      border: 2px solid #fdcb8d;
      border-radius: 3px;
      // height: 60px;
      // line-height: 60px;
      padding: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      // position: relative;
      // align-items: center;
      justify-content: space-between;

      .board_tipicon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        // position: absolute;
        // left: 15px;
        // top: 18px;
      }
      .board_info_container {
        flex: 1;
      }

      .board_info_container ::v-deep p{ // 穿透v-html的样式
        display: inline;
      }

      .board_close {
        width: 24px;
        height: 24px;
        // position: absolute;
        // right: 15px;
        // top: 15px;
        cursor: pointer;
        margin-left: 10px;
      }

    }

    .work-task {
      .daiban {
        background-image: linear-gradient(to right, #3cb7ff, #1b94ff);
      }
      .faqi {
        margin: 0 16px;
        background-image: linear-gradient(to right, #6970ff, #6150ff);
      }
      .chuli {
        background-image: linear-gradient(to right, #6196ff, #0e61ff);
      }
      .workItem {
        cursor: pointer;
        padding: 45px 35px 0px 35px;
        height: 190px;
        width: 40%;
        border-radius: 3px;
        .left {
          color: white;
          .title {
            font-size: 18px;
          }
          .num {
            font-size: 16px;
            margin-top: 18px;
          }
        }
        .right {
          margin-top: 15px;
        }
      }
    }

    .informationAnn {
      margin-top: 30px;
      height: 200px;
      width: 100%;
      border-radius: 3px;
      background: #fff url(./../../../assets/zixunxiaoxi_zhuangshi.png);
      opacity: 0.9;
      .informationAnn-title {
        padding: 0px 30px;
        height: 60px;
        line-height: 60px;
        .title {
          font-size: 18px;
          color: #333333;
          font-weight: 600;
        }
      }
      .list {
        padding: 17px 30px;
        .item {
          height: 35px;
          line-height: 35px;
          width: 70%;
          .index {
            font-weight: 600;
            font-size: 16px;
            margin-right: 10px;
            cursor: pointer;
          }
          .item-title {
            font-size: 16px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
          }
        }
      }
    }

    .Echarts {
      width: 100%;
      .chart {
        width: 100%;
        margin-top: 40px;
        padding-top: 40px;
        background-color: #fff;
      }
    }
  }
}

.notice_card_wrapper {
  .inner_container {
    // margin-left: 100%;
    // width: 50%;
    animation: myMove 30s linear infinite; // 定义动画
    animation-fill-mode: forwards;
  }
  // 文字无缝滚动
  @keyframes myMove {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-2500px)
    }
    
  }
}

.noticeModal {
  // max-height: 600px;

  // padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .notice_html ::v-deep img{
      width: 100%;
      height: 100%;
  }
  .notice_content {
    flex-grow: 1;
    overflow-y: auto;
  }
  .board_img {
    width: 100%;
    height: 200px;
    // height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .modal_update_content {
    padding-top: 24px;
  }
  .board_btn {
    height: 60px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .btn {
      background-color: #2b60d9;
      border-radius: 3px;
      width: 120px;
      height: 40px;
    }
  }
}

.describe {
  margin-top: 20px;
}
.img {
  width: 40px;
  height: 40px;
}
::v-deep(.ant-table-bordered .ant-table-tbody > tr > td) {
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.subject_title {
  cursor: pointer;
  color: #0e61ff;
}

// ::v-deep(.ant-modal-header) {
//   display: none !important;
// }

// ::v-deep(.ant-modal-body) {
//   padding: 0 !important;
// }



</style>
